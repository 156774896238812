.historyButton {
  background-color: rgb(250, 183, 58);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: -5px;
  border: none;
  border-radius: 4px;
}

.wifiAnalyzer {
  height: 100%;
  width: 100%;
  position: fixed;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 999;
}

.wifiAnalyzerSpan {
  height: "600px";
  width: "600px";
  background-color: "#fff5e3";
  margin-left: "32%";
  margin-top: "5%";
  border-radius: "10px";
}

