#sitesSelectOption {
  padding: 10px;
  border: solid rgba(255, 255, 255) 1px;
  background-color: transparent;
  border-radius: 4px;
  width: 60%;
  color: rgba(255, 255, 255);
  margin-top: -6px;
}

#sitesSelectOption:hover {
  border: solid rgba(255, 255, 255, 0.6) 1px;
  color: rgba(255, 255, 255, 0.6);
}

.sitesOption {
  color: rgba(29, 27, 65, 0.6);
}

.option-list-container {
  margin-bottom: 25px;
}
@media (max-width: 768px) {
  .option-list-container {
    margin-top: 50px;
  }
}

