:root {
  --infoButton-color: rgba(29, 27, 65, 0.6);
  --chart-color: rgba(29, 27, 65, 0.6);

  --details-color: rgba(29, 27, 65, 0.6);

  --bg-color: linear-gradient(
      180deg,
      rgba(0, 10, 50, 0.72) 0%,
      rgba(15, 48, 171, 0.72) 32.96%,
      rgba(21, 67, 240, 0.72) 63.01%,
      rgba(88, 86, 214, 0.72) 100%
    ),
    linear-gradient(0deg, #000a32, #000a32);

  /*Ekran1
   --bg-color: rgba(88, 86, 214, 1);
  */

  /*
  --bg-color: linear-gradient(180deg, rgb(112 83 169 / 72%) 0%, rgb(94 57 172 / 72%) 32.96%, rgb(94 57 167 / 72%) 63.01%, rgb(51 28 97 / 72%) 100%),
  linear-gradient(0deg, #000A32, #000A32);
  */

  --nav-color: rgba(0, 10, 50, 1);
  --timeMenu-color: rgba(255, 255, 255, 0.13);
  --ssid-color: rgb(108, 124, 158);
}

[data-theme="dark"] {
  --infoButton-color: rgba(14, 13, 31, 0.6);
  --details-color: #202024;
  --chart-color: #18181b;
  --bg-color: #0e0e18;
  --nav-color: #18181b;
  --timeMenu-color: rgba(255, 255, 255, 0.096);
  --ssid-color: rgb(63, 76, 104);
}

@font-face {
  font-family: "Nunito Sans Light";
  font-style: normal;
  font-weight: 300;
  src: local("Nunito Sans Light"), local("NunitoSans-Light"),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe03MImSLYBIv1o4X1M8cc8WAc5tU1ECVZl_.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Sans Regular"), local("NunitoSans-Regular"),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe03MImSLYBIv1o4X1M8cc8WAc5tU1ECVZl_.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans Semi-bold";
  font-style: normal;
  font-weight: 600;
  src: local("Nunito Sans Semi-bold"), local("NunitoSans-Semi-bold"),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe03MImSLYBIv1o4X1M8cc8WAc5tU1ECVZl_.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Nunito Sans Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Sans Bold"), local("NunitoSans-Bold"),
    url(https://fonts.gstatic.com/s/nunitosans/v3/pe03MImSLYBIv1o4X1M8cc8WAc5tU1ECVZl_.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  background-color: var(--bg-color);
}
.App {
  text-align: center;
  background-color: var(--bg-color);
}

/* Nav bar section */
.section {
  /* padding-top: 45px !important; */
  padding-bottom: 0px !important;
}
.navbar.is-primary {
  box-shadow: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
#topMenu {
  background-color: var(--nav-color);
  position: sticky;
  top: 0;
  z-index: 201;
  height: 65px;
  font-style: normal;
  font-size: 15px;
}
#menuButton {
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQQnjTJapw9wdG8Y5Eern7jXYs-eu7rTd6Fkp-DM5MsDfJxF_cF");
  background-size: cover;
  margin-top: 10px;
}

#timeMenu {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
#userName {
  background-size: cover;
  background-color: var(--nav-color);
  color: wheat;
  cursor: default;
  font-size: 17px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  border: none;
}
#logoArea {
  display: contents;
}
#imgLogo {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  margin-left: 20px;
  border-radius: 50%;
}
#imgLogo2{
  height: 50px;
  width: 80px;
  margin-right: 10px;
  margin-left: 20px;
}
#companyName {
  position: absolute;
  margin-top: 13px;
  color: white;
}
.is-kpi {
  padding-top: 10px !important;
}
.is-logo {
  height: 50px;
  width: 190px;
}
.logo-wrapper {
  height: 100px;
  width: 16px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 750 !important;
  letter-spacing: 2.5px;
  line-height: 21px;
  padding-left: 8px;
  padding-top: 20px;
}
.navbar-brand {
  padding-bottom: auto !important;
  padding-left: 25px !important;
  padding-top: auto !important;
}
a {
  cursor: pointer;
  text-decoration: none;
}
.btn-link {
  color: #fdfdfd;
}
#nav {
  background-color: var(--nav-color);
  color: white;
  padding: 15px;
  z-index: 1000 !important;
}

@media (min-width: 768px) {
  #nav {
    min-height: 100vh;
  }
}
#content-body {
  background: var(--bg-color);
  color: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
}
@media (min-height: 480px) and (max-height: 700px) {
  .menuscroll {
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .menuscroll::-webkit-scrollbar-track {
    border: none;
    border-radius: 10px;
    background-color: var(--nav-color);
  }

  .menuscroll::-webkit-scrollbar {
    width: 10px;
    background-color: var(--nav-color);
  }

  .menuscroll::-webkit-scrollbar-thumb {
    /* scroll button */
    background-color: #1e1d56;
  }
}
.logo {
  font-size: 18px;
  font-family: Nunito Sans Light;
}
.macName {
  width: 130px !important;
  height: 30px !important;
  border: none !important;
  color: white;
  border-radius: 10px !important;
  position: relative;
  background-color: var(--ssid-color) !important;
  transition: 0.3s all !important;
  margin-top: 10px !important;
  margin-bottom: 2px !important;
  padding: 8px !important;
  font-size: 11 !important;
  text-align: right !important;
  font-weight: bold;
  float: left;
  border: 1px solid #172d5b;
}
.macSubmit {
  width: 50px !important;
  height: 30px !important;
  border: none !important;
  font-size: 11 !important;
  border-radius: 10px !important;
  position: relative !important;
  background-color: rgba(255, 255, 255, 0.13) !important;
  transition: 0.3s all !important;
  margin-left: 14px !important;
  margin-top: px !important;
  margin-bottom: 2px !important;
  padding: 0px !important;
  color: white !important;
}
.left-options {
  list-style: none;
  padding: 0px;
  /* margin: 0px -15px 0 9px; */
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
  border-bottom: 1px solid #ffffff60;
  margin-bottom: 0px;
}
.left-options li a {
  display: block;
  padding: 10px 0 10px 10px;
  color: #8c8ba6;
  font-size: 15px;
  text-decoration: none;
  margin-bottom: 3px;
}
/* .left-options li:before {
  content: "";
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  margin-top: 8px;
  opacity: 0.6;
} */
.left-options li:hover > a {
  color: #fdfdfd;
}
/* .left-options li:hover:before {
  opacity: 1;
} */
.left-options li.active > a {
  border-right: 3px solid #83e8a2;
  box-shadow: 1px #83e8a2 inset;
  color: #fdfdfd;
}
.left-options-sub {
  list-style: none;
  padding: 0px;
  margin-left: 10%;
  margin-top: -10px;
  width: 90%;
  height: 100%;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
}
.left-options-sub li a {
  display: block;
  padding: 5px 0 5px 10px;
  color: #8c8ba6;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 3px;
}
/* .left-options-sub li:before {
  content: "";
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 8px;
  margin-top: 8px;
  opacity: 0.6;
} */
.left-options-sub li:hover > a {
  color: #fdfdfd;
}
/* .left-options-sub li:hover:before {
  opacity: 1;
} */
.left-options-sub li.active > a {
  border-right: 3px solid #83e8a2;
  box-shadow: 1px #83e8a2 inset;
  color: #fdfdfd;
}
/* .left-options li.active:before {
  opacity: 1;
}
.left-options li#DASHBOARD:before {
  background-image: url("https://img.icons8.com/officel/80/000000/rebalance-portfolio.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#ROUTERS:before {
  background-image: url("https://img.icons8.com/dusk/64/000000/router.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#DETAILS:before {
  background-image: url("https://img.icons8.com/plasticine/100/000000/combo-chart.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#MAP:before {
  background-image: url("https://img.icons8.com/dusk/64/000000/map.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#FILTER:before {
  background-image: url("https://img.icons8.com/color/48/000000/filter.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#SETTINGS:before {
  background-image: url("https://img.icons8.com/color/48/000000/settings.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#DEVICES:before {
  background-image: url("https://img.icons8.com/doodle/48/000000/cell-phone.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#TRACEROUTE:before {
  background-image: url("https://img.icons8.com/cotton/64/000000/tree.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#GRIDVIEW:before {
  background-image: url("https://img.icons8.com/office/16/000000/activity-grid-2.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#LOGOUT:before {
  background-image: url("https://img.icons8.com/dusk/64/000000/exit-sign.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  display: none;
}
.left-options li#SUNBURST:before {
  background-image: url("https://img.icons8.com/cotton/64/000000/network.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#MEMBERS:before {
  background-image: url("https://img.icons8.com/dusk/64/000000/conference-call.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.left-options li#CUSTOMERSERVICE:before {
  background-image: url("https://img.icons8.com/office/40/000000/customer-support.png");
  background-repeat: no-repeat;
  background-size: 24px 24px;
} */
#LOGOUT {
  display: none;
}
#ACCOUNT {
  display: none;
}

.inputForCustomer {
  margin-top: 9%;
  width: 50%;
  text-align: center;
  border-top: 0.5px solid;
  border-bottom: 0.5px solid;
}

#type1 {
  padding: 8%;
}

.typeOneCustomerr {
  padding: 45%;
}

#mapFilterSelect2 {
  font-size: 15px;
  margin-left: 5%;
  margin-top: 10%;
  color: white;
  height: 35px;
  background-color: #243265;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  outline: none;
}

#mapFilterTry {
  display: none;
}

@media all and (max-width: 590px) {
  #mapFilterTry {
    display: flex;
    font-size: 15px;
    margin-left: 5%;
    margin-top: 10%;
    color: white;
    height: 35px;
    background-color: #243265;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    outline: none;
    outline: none;
  }

  #wirelessMenu {
    margin-top: 2rem !important;

    margin-bottom: 3rem !important;
    margin-left: auto;
    margin-right: auto;
    width: 50em;
  }

  #wirelessMenuCustomerService {
    margin-top: 2rem !important;

    margin-bottom: 1rem !important;
    margin-left: auto;
    margin-right: auto;
    width: 50em;
  }

  .buttonwrapper li {
    display: block !important;
    float: left !important;
    height: 50px !important;
    width: 50px !important;
  }

  .buttonwrapperCustomerService li {
    display: block !important;
    float: left !important;
    height: 50px !important;
    width: 50px !important;
  }

  .buttonwrapper li.active label {
    background-color: #182142 !important;
    border-radius: 50px !important;
    height: 50px !important;
  }

  .buttonwrapperCustomerService li.active label {
    background-color: #182142 !important;
    border-radius: 50px !important;
    height: 50px !important;
  }

  #selecTime {
    /* background-color: #172d5b; */
    background-color: #404b75;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: whitesmoke;
    height: 40px;
    outline: none;
    font-weight: bold;
    text-indent: 40%;
    margin: -5% 5%;
    border: 1px solid #172d5b;
    border-radius: 5px;
    display: flex !important;
  }

  #detailsRec {
    height: 215px !important;
  }
  #userName {
    visibility: hidden;
  }
  #topMenu {
    visibility: hidden;
  }
  #nav {
    position: sticky;
    top: 0rem;
    z-index: 1000;
  }
  .email {
    width: 95% !important;
    margin: 0 auto !important;
  }
  .password {
    width: 95% !important;
    margin: 0 auto !important;
  }

  #submitButton {
    width: 92% !important;
    margin: 20px auto !important;
    margin-left: 15px !important;
  }
  #saveSettings {
    width: 40% !important;
  }
  .passwordLabel {
    margin: 3px !important;
  }
  .emailLabel {
    margin: 3px !important;
  }
  #companyName {
    position: absolute;
    margin-top: 13px;
    top: auto;
  }
  #ul {
    display: none;
  }
  #customerServiceSelect {
    background-color: #172d5b;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: whitesmoke;
    height: 40px;
    outline: none;
    font-weight: bold;
    border: 1px solid #172d5b;
    border-radius: 5px;
    display: flex !important;
  }

  #hikmet {
    background-color: #172d5b;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: whitesmoke;
    height: 40px;
    outline: none;
    font-weight: bold;
    text-indent: 40%;
    border: 1px solid #172d5b;
    border-radius: 5px;
    display: flex !important;
  }
  #shortCodeExplanation,
  #activateExplanation {
    padding: 2rem !important;
    margin: 0px !important;
  }
  #codePage {
    position: relative !important;
  }
  .store {
    width: 44% !important;
  }
  #settingsContainer {
    margin-top: -45px;
  }
  #thresholdTitle {
    margin: 0 !important;
  }
  #ssid {
    width: 100%;
  }
  #reset {
    width: 50% !important;
  }
  #forgotemail,
  #news,
  #webinar {
    width: 90% !important;
    margin-left: 5% !important;
  }
  #loginPageButton {
    width: 90% !important;
  }
  .companySettings {
    width: 90% !important;
  }
  #inviteContanier {
    margin-top: 4% !important;
    height: 300px !important;
  }
  #invitemailtext {
    width: 90% !important;
  }
  #invitemail {
    margin-left: 0px !important;
  }
  #roleselectdiv {
    display: flex !important;
    margin-right: -10px !important;
  }
  #mapDiv {
    height: 600px !important;
  }

  .popimg {
    display: table-cell;
    margin-top: 0px !important;
    padding: 0px !important;
  }

  .left-options li#LOGOUT:before {
    background-image: url("https://img.icons8.com/dusk/64/000000/exit-sign.png");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    display: inline;
  }
  #LOGOUT {
    display: inline;
  }
  .CookieConsent {
    display: block !important;
  }
  #traceContainer {
    position: initial !important;
  }
  #traceExp {
    display: none !important;
  }
  #noDataTrace {
    font-size: 12px !important;
  }
  .setTimeButtonMap {
    display: none;
  }
  .timeLabelMap {
    display: none;
  }
  .setTimeButtonCalendar {
    display: contents;
  }
  #mapselect {
    height: 90px !important;
    width: 250px !important;
    margin-left: 5px !important;
    font-size: 10px !important;
  }
  #filterselect {
    height: 90px !important;
    width: 250px !important;
    margin-left: 5px !important;
    font-size: 10px !important;
  }
  #timeTravelMap {
    margin-left: 8% !important;
  }
  #timeTravelTraceTable {
    margin-left: 8% !important;
  }
  .RecommendationStyle {
    font-size: 13px !important;
  }
  .jss1 {
    width: 100% !important;
  }
  #membersPage,
  #gridPage {
    min-height: -webkit-fill-available !important;
  }
  #acpSettingsPaper,
  #roomSettingsPaper {
    width: 100% !important;
  }
  #mapSelection {
    overflow-x: auto;
    margin-bottom: 15px;
  }
  #gridRadioButtonsDiv {
    overflow-x: auto;
  }
}
@media all and (max-width: 630px) {
  .macName {
    width: 40% !important;
  }
  #companyName {
    position: absolute;
  }
  .makeStyles-root-1 {
    width: 100% !important;
    border-radius: 50px !important;
  }
  #detailsTable, #heatMapTable {
    height: 500px !important;
    width: 200px;
  }
  #detailsCoverage, #detailsBrandModelAndroid {
    margin-right: 10px !important;
  }
  #detailsTrace {
    margin: 0 !important;
  }
  #detailsMap {
    margin: 0 !important;
  }
  #detailsHeatMap {
    margin: 0 !important;
  }
  #detailsBottom {
    display: contents !important;
  }
}
.path {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #979797;
  opacity: 0.05;
  margin-bottom: 0px;
  margin-top: 30px;
}
.pathRating {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid #979797;
  opacity: 0.05;
  margin-bottom: 13px;
  margin-top: 13px;
}
.pathSetting {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 2px solid black;
  opacity: 0.05;
  margin-bottom: 13px;
  margin-top: 13px;
}
.pathRatinglist {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid black;
  opacity: 0.05;
  margin-bottom: 13px;
  margin-top: 13px;
}
.buttonwrapper {
  list-style: none;
  padding: 5px;
  width: auto;
  border-radius: 50px;
  background-color: var(--timeMenu-color);

  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
}
.buttonwrapperCustomerService {
  list-style: none;
  padding: 5px;
  width: auto;
  border-radius: 50px;
  background-color: var(--timeMenu-color);
  margin-top: 2rem;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
}

p {
  font-size: 30px;
  color: rgb(230, 82, 109);
  font-family: "Montserrat", sans-serif;
  text-align: center;
}
.buttonwrapper li {
  display: block;
  float: left;
  height: 35px;
  width: 100px;
}
.buttonwrapperCustomerService li {
  display: block;
  float: left;
  width: 100px;
}

.buttonwrapper li:first-child {
  margin-left: 0;
}

.buttonwrapperCustomerService li:first-child {
  margin-left: 0;
}

.buttonwrapper label {
  display: block;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  padding: 8px 10px;
  margin-bottom: 0;
  border-radius: 50%;
  cursor: pointer;
}

.buttonwrapperCustomerService label {
  display: block;
  color: #ffffff;
  opacity: 1;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  padding: 8px 10px;
  margin-bottom: 0;
  border-radius: 50%;
  cursor: pointer;
}

.MuiButton-root {
  font-weight: 1000;
}
.buttonwrapper li.active label {
  background-color: #182142;
  border-radius: 50px;
  height: 35px;
}

.buttonwrapperCustomerService li.active label {
  background-color: #182142;
  border-radius: 50px;
  height: 35px;
}

.chart {
  display: inline;
}
.chart-card {
  background-color: var(--chart-color);
  border-radius: 4px;
  overflow: hidden;
}
.chart-card-scroll {
  background-color: var(--chart-color);
  border-radius: 4px;
  margin-top: -25px;
  /* overflow-y: scroll; */
  max-height: 1000px;
}
.chart-card-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}
.chart-card-scroll::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0.6, 0, 0, 0);
}
.chart-card-scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border-width: 1.7px;
  border-style: solid;
  border-color: #83e8a2;
}
.chart svg {
  background-color: transparent !important;
}
.chart-title {
  font-size: 17px;
  padding: 7px 15px 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-left: 5%;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
}
.chart-title1 {
  font-size: 17px;
  padding: 7px 15px 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
}

@media (min-width: 768px) {
  #nav {
    position: sticky;
    top: 0rem;
    z-index: 1000;
    height: 100vh;
  }
  .logo {
    padding-top: 25px;
  }
  .bd-links {
    display: block !important;
  }
  nav {
    padding-top: 66px;
  }
  .chart {
    margin-top: 10px;
    /* margin-left: auto ;
    margin-right: auto ; */
  }
  #inviteExplanation {
    margin-top: 33px !important;
  }
}
@media (min-width: 1600px) {
  nav {
    padding-left: 20px;
  }
}
.chart1-em {
  width: 100%;
}
.chart {
  margin-left: 10px;
}
#e1,
#e2,
#e3,
#e4,
#e5 {
  border: none;
  cursor: pointer;
  margin: 25px;
  /* width:8%; */
}
#e1,
#e2,
#u1,
#u2,
#c1,
#c2,
#a1,
#a2,
#e3,
#e4,
#e5 {
  cursor: pointer;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .db-chart > .col-lg-6 {
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .db-chart > .col-lg-6 {
    width: 100% !important;
  }
  .chart {
    /* margin-left:30px!important ; */
  }
}

@media (max-width: 768px) {
  #content-body {
    padding: 0 15px !important;
  }
  .chart {
    margin-left: -80px;
  }
  .left-options {
  }
  #us-chart-container,
  #em-chart-container {
    width: 950px;
  }
  .res-chart {
    overflow-x: auto;
  }
  .res-chart--parent {
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:horizontal {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: rgba(253, 253, 253, 1);
  }
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(253, 253, 253, 0.2);
  }

  #timeTravelMap {
    margin-top: -10px;
    background-color: transparent !important;
    position: relative !important;
    display: table-footer-group !important;
  }
  #timeTravelTraceTable {
    margin-top: -10px;
    background-color: transparent !important;
    position: relative !important;
    display: table-footer-group !important;
  }
}

@media (max-width: 1200px) {
  .time-control {
    padding: 30px 5px !important;
  }
  .time-control > div {
    margin-left: 0px !important;
  }
}
@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  #companyName {
    position: absolute;
    margin-top: 13px;
    top: 20px;
  }
}
.badge {
  height: 40px;
  width: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 8px 10px 0 rgba(52, 72, 94, 0.17);
}

.badge.is-star {
  background-color: #fab73a;
}
.container {
  padding-top: 3rem;
  padding-bottom: 0rem;
}
.is-kpi {
  padding-top: 10px !important;
}
#beta {
  color: #e6526d;
  font-size: 12px;
  font-family: "Nunito Sans Light";
  font-weight: lighter;
}
.indicator {
  display: flex !important;
  justify-content: left !important;
  padding-top: 5px !important;
  color: #ffffff;
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  line-height: 35px;
}
.sub-indicator {
  display: flex !important;
  justify-content: left !important;
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 16px;
  /* letter-spacing: 0.5px; */
}
.has-image {
  display: flex;
  padding-bottom: 21px;
  align-items: center;
}
.has-images {
  display: flex;
  padding-bottom: 21px;
  align-items: center;
  margin-bottom: 40px;
}
.card {
  border: 1px solid rgba(72, 72, 72, 0.17) !important;
  border-radius: 4px !important;
  background: var(--chart-color);
  margin-top: 25px;
}
.image-has-text {
  padding-left: 16px;
  line-height: 40px !important;
}
.snapshot {
  display: inline-block;
  color: #d2d2d2;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: -0.2px;
  font-weight: 100;
}
.card-content {
  padding: 0.5rem !important;
}
.column {
  padding: 0.75rem 0.75rem 0.5rem 0.75rem;
}
.rater {
  margin-bottom: 11px;
}
.columns {
  flex-wrap: wrap;
  align-items: stretch;
}
.column.header {
  display: flex !important;
  justify-content: flex-end !important;
  padding-top: 20px !important;
  padding-bottom: 0px;
}
.columns-kpi {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 560px) {
  .column.is-half,
  .column.is-half-tablet {
    flex: none;
    width: 50% !important;
  }
}
@media print, screen and (min-width: 1080px) {
  .column.is-one-quarter-desktop {
    flex: none;
    width: 25% !important;
  }
}
@media screen and (max-width: 559px) {
  .column.is-mobile,
  .column.is-full,
  .column.is-full-mobile {
    flex: none;
    width: 100% !important;
  }
  .container {
    padding-top: 200px;
  }
  .container2 {
    padding-top: 3rem;
    position: fixed;
    z-index: 1;
    width: 100%;
    border-bottom: #434456;
    border-bottom-style: solid;
    -radius: 20px;
    margin-top: 0 !important;
  }
  .container2 > div {
    margin-bottom: 0 !important;
  }
  .img-car {
    width: 400px;
    height: 120px;
  }
  .carousel .legend2 {
    margin-top: 0ch;
    margin-bottom: 15px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    height: 10%;
    border-radius: 0px;
    background: none;
    color: #fff;
    padding: 15px;
    font-size: 18px;
    text-align: center;
    opacity: 100;
  }
  .carousel .legend {
    margin-top: 0ch;
    margin-bottom: 25px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    height: 10%;
    border-radius: 0px;
    background: none;
    color: #fff;
    padding: 15px;
    font-size: 18px;
    text-align: center;
    opacity: 100;
  }
}

.parent {
  width: auto;
  height: auto;
  display: block;
}
.form-control {
  outline: 0em;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group {
  margin-bottom: 1rem;
  margin-left: 10px;
  margin-right: 10px;
}
.form-label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  color: azure;
  letter-spacing: 1px;
  font-size: 0.7rem;
}
.btn:disabled {
  color: #fff;
  background-color: #1543f0;
  border: none;
  opacity: 1 !important;
}
.btn:hover {
  background-color: rgb(250, 70, 46);
}
.btn-block {
  display: block;
  width: 100%;
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4rem;
  border: none;
  background-color: #fd6d5b;
  outline: 0em;
}

*,
::after,
::before {
  box-sizing: border-box;
}
form {
  margin-top: 0px;
}
b {
  font-size: medium;
  font-family: Helvetica, Arial, sans-serif;
}
.email {
  width: 22%;
  margin-left: 39%;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #1543f0 1px;
}
.password {
  width: 22%;
  margin-left: 39%;
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid #1543f0 1px;
}

#submitButton {
  width: 19.5%;
  margin-left: 40.3%;
  margin-top: 25px;
  box-shadow: 0 5px 12px 0 rgba(20, 11, 32, 1);
}
.passwordLabel {
  margin-left: 39%;
  color: #1543f0;
  font-family: Helvetica, Arial, sans-serif;
}
.emailLabel {
  margin-left: 39%;
  color: #1543f0;
  font-family: Helvetica, Arial, sans-serif;
}
#loginLogo {
  height: 34px;
  width: 34px;
  margin-right: 13px;
  margin-bottom: 9px;
}
#header {
  height: 80px;
  width: 100%;
  background: #1543f0;
  -webkit-box-shadow: 0 5px 32px 0 rgba(20, 11, 32, 1);
  box-shadow: 0 5px 32px 0 rgba(20, 11, 32, 1);
}
#form {
  background: whitesmoke;
}

#selecTime {
  display: none;
}
#customerServiceSelect {
  display: none;
}
/* rect {
  fill: rgba(61, 79, 156, 0.322);
} */
#Brand-Model {
  font-size: "8px";
}
.react-rater-star.is-active {
  color: #fab73a !important;
}
#infoButton {
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background: var(--infoButton-color);
  border: none;
  height: 25px;
  width: 25px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 5px;
}
#infoRow {
  background-color: var(--details-color);
  border: none !important;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}
#infos {
  border-left: 1px solid rgba(29, 27, 65, 0.6);
  border-bottom: 1px solid rgba(29, 27, 65, 0.6);
  border-right: 1px solid rgba(29, 27, 65, 0.6);
}
#accordionCards {
  margin-top: 0px !important;
  border: none !important;
  background-color: var(--details-color);
  border-radius: 0px !important;
}
#infoCards {
  margin: 0px !important;
}
#infoHeader {
  justify-content: center !important;
}
.popup {
  margin-left: auto;
  color: #181745;
}
#ssid {
  float: left;
  margin-top: 10px;
  background-color: var(--ssid-color);
  font-family: Helvetica, Arial, sans-serif;
  color: whitesmoke;
  outline: none;
  font-weight: bold;
  border: 1px solid #172d5b;
  border-radius: 5px;
  height: 30px;
}
#mapselect {
  margin-top: 10px;
  margin-left: 30px;
  /* background-color: var(--timeMenu-color); */
  font-family: Helvetica, Arial, sans-serif;
  color: whitesmoke;
  outline: none;
  border-radius: 50px;
  height: 45px;
  width: 150px;
  border: none;
}
#filterselect {
  margin-top: 10px;
  margin-left: 30px;
  /* background-color: var(--timeMenu-color); */
  font-family: Helvetica, Arial, sans-serif;
  color: whitesmoke;
  outline: none;
  border-radius: 50px;
  height: 45px;
  width: 150px;
  border: none;
}
#infoContainer {
  padding: 1rem;
}
#searchContainer {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
#logButtons {
  margin-top: 40px;
  text-align: center;
}
#ambeent {
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
#loginLogoWrapper {
  margin-left: 10%;
}
.noData {
  z-index: 1;
  position: absolute;
  color: #181745;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 25px;
  margin-top: 105px;
  width: 95%;
  font-weight: bolder;
}
#shortCodeExplanation {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 24px;
}

#welcome {
  height: 52px;
  font-family: Helvetica;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 88px;
}
.store {
  width: 256px;
  height: 76px;
  object-fit: contain;
  text-align: center;
}
#code {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}
#shortCode {
  font-family: Helvetica;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 8px;
}
#downloadLabel {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
}

.changeChartButton {
  float: right;
  background-size: contain !important;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  margin-right: 5px;
}
#lngSelect {
  color: white;
  outline: none;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  font-size: 22px;
  cursor: pointer;
  width: auto;
}
.lng {
  background: var(--nav-color);
  font-weight: bold;
  font-size: 20px;
}
.lngM {
  background: #1543f0;
  font-weight: bold;
  font-size: 20px;
}
#lngSelectM {
  color: white;
  outline: none;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  width: auto;
  margin-left: 5px;
}
#rightMenu {
  float: right;
  margin-top: 7px;
}
#settingsContainer {
  background: rgba(29, 27, 65, 0.6);
}
#thresholdRange {
  width: 60%;
  margin-bottom: 15px;
}
#settingsTitle {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
}
#settingsTitle1 {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 17px;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
}
.slidecontainer {
  border-bottom: 1px solid #242268;
}
.range {
  display: inline-block;
  width: 100%;
}
input[type="range"] {
  -webkit-appearance: none;
  position: relative;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 2px;
  border: none;
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 510%;
  background: #141414;
  border: 2px solid #515151;
  margin-top: -5px;
  cursor: pointer;
}
.focused::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.15);
}
.disabled::-webkit-slider-thumb {
  box-shadow: 0 0 0 3px #141414;
  background: #515151 !important;
  border-color: #515151 !important;
}
input[type="range"]:focus {
  outline: none;
}
.rangeM input[type="range"].disabled::-webkit-slider-runnable-track {
  background: #515151 !important;
}
.rangeM input[type="range"]::-webkit-slider-thumb {
  background: #3f51b5;
  border-color: #3f51b5;
}
.range:hover input[type="range"]:before {
  color: white;
  content: "50";
  position: absolute;
  font-family: Roboto Slab;
  top: -49px;
  background: #3f51b5;
  padding: 8px 0 3px;
  font-size: 14px;
  width: 30px;
  text-align: center;
  border-radius: 100% 100% 0 0;
}
.range:hover input[type="range"]:after {
  content: "";
  position: absolute;
  top: -19px;
  left: 50px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 8px solid #3f51b5;
  font-family: Roboto Slab;
}
#gb {
  font-size: inherit !important;
  font-family: Helvetica, Arial, sans-serif;
}
#saveSettings {
  width: 19.5%;
  margin-top: 25px;
  box-shadow: 0 5px 12px 0 rgba(20, 11, 32, 1);
  margin-right: 5px;
}
#shortCodeBorder {
  width: 224px;
  height: 56px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.4);
  display: inline-grid;
}
#codePage,
#resetPage {
  text-align: center;
  background-color: rgb(21, 67, 240);
  position: absolute;
  height: 100%;
  width: 100%;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}
#thresholdTitle {
  display: inline-block;
  border-bottom: 1px solid #242268;
  margin-top: 25px;
}
#defaultSettings {
  width: 19.5%;
  margin-top: 25px;
  box-shadow: 0 5px 12px 0 rgba(20, 11, 32, 1);
  margin-left: 5px;
}
.theme-switch-wrapper {
  align-items: center;
}
.theme-switch {
  height: 20px;
  position: relative;
  width: 50px;
  margin-left: 15px;
}
.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  content: "";
  height: 20px;
  left: 0px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}

input:checked + .slider {
  background-color: #0e0718;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
#scode {
  border: 3px solid rgb(230, 82, 109);
  letter-spacing: 10px;
  padding: 13px;
  font-size: 21px;
}

#news,
#webinar {
  background: transparent;
  color: black;
  font-size: 15px;
  margin-top: 20px;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
}

#loginPageButton {
  margin-top: 20px;
  width: 20%;
}
#rpassword {
  height: 52px;
  font-family: Helvetica;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  margin-top: 88px;
  margin-bottom: 50px;
  color: #1543f0;
}
#lpage {
  display: none;
}
#activateLabel {
  text-decoration: underline;
  font-size: 17px;
  color: red;
}
.iosstore {
  width: 163px;
  height: 53px;
  object-fit: contain;
  margin-top: 30px;
  margin-left: -7px;
  display: -webkit-box;
}
.androidstore {
  width: 163px;
  height: 53px;
  object-fit: contain;
  margin-top: 12px;
  margin-left: -7px;
}
.companySettings {
  height: 300px;
  width: 41%;
  background-color: var(--chart-color);
  margin: 4%;
}
#inviteContanier {
  margin: 4%;
  background-color: var(--chart-color);
  width: 90%;
  height: 250px;
  margin-top: 0px;
}
.settingsHeader {
  color: rgb(230, 82, 109);
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 15px;
  display: block;
}

#changePP {
  font-family: Helvetica, Arial, sans-serif;
  text-decoration: underline;
  cursor: pointer;
}
#standard-basic-label {
  font-size: 15px;
  color: rgba(255, 255, 255, 0.383);
  text-indent: 5px;
}
#standard-basic {
  margin-top: -10px;
  color: white;
}

#savePSettings {
  float: right;
}
.showDetailsfromMap {
  background-color: rgb(230, 82, 109);
  border: none;
  color: white;
  width: 100px;
  height: 25px;
  margin: 10px;
  border-radius: 50px;
  /* transition:transform 2s; */
}
.savePSettings2 {
  background-color: rgb(230, 82, 109);
  border: none;
  color: white;
  width: 150px;
  height: 35px;
  margin: 6px;
  border-radius: 50px;
  /* transition:transform 2s; */
}

.savePSettings3 {
  border: none;
  color: white;
  width: 15rem;
  height: 35px;
  margin-top: 2rem;
  border-radius: 50px;
  /* transition:transform 2s; */
}
/* .savePSettings2:hover {
  transform: rotate(360deg);
} */

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(255, 255, 255, 0.383) !important;
}
@-moz-document url-prefix() {
  #standard-basic {
    margin-top: 0px;
  }
}

#resendButton {
  width: fit-content;
  margin: 0 !important;
}
#inviteExplanation {
  font-size: 13px;
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 45px;
  opacity: 0.3;
  margin-left: 20px;
  margin-right: 20px;
}
.rol {
  background: var(--chart-color);
}
#resendDiv {
  display: -webkit-inline-box;
  text-align: center;
  margin-top: 5%;
}
#hrefHelp {
  font-size: 15px;
  text-decoration: underline;
  background: transparent;
  border: none;
  color: deepskyblue;
}
#hrefHelp:hover {
  color: blue;
}
#howTo {
  text-align: center;
  opacity: 0.5;
  font-size: 11px;
  margin-top: 30px;
  font-family: Helvetica;
}

.chooselogo {
  height: 75px;
  width: 75px;
  margin-right: 20px;
  border-radius: 50%;
  margin-left: -5px;
}
.popimg {
  margin-top: 30px;
  padding: 5px;
}
#barChartDiv,
#barChartDiv2 {
  background-color: var(--chart-color);
}
#infopdf {
  text-align: center;
  font-size: 20px;
  margin-top: 25px;
  cursor: pointer;
  color: #1543f0;
  font-family: helvetica;
  text-decoration: underline;
}
#routerBrand {
  color: rgb(161, 161, 15) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
}
#identified {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 15px;
}
[id^="google-visualization-errors"] {
  display: none;
}
#submitCustomer {
  float: left;
  height: 30px;
  margin: 10px;
  background-color: #e6526d;
  border: none;
  border-radius: 10px;
  width: 90px;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}

.setTimeButtonMap,
.setTimeButtonCalendar {
  width: 70px;
  border: none;
  background-color: #182142;
  border-radius: 50px;
  height: 35px;
  color: #fff;
  margin: 6px;
}
.setCalendar {
  width: 260px;
  border: none;
  background-color: #182142;
  border-radius: 50px;
  height: 35px;
  color: #fff;
  margin: 6px;
}
.setCalendar > div > div.react-datepicker__input-container input {
  width: 240px;
}

#wirelessMenu {
  width: fit-content;
  height: 45px;
  font-family: Helvetica, Arial, sans-serif;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

#wirelessMenuCustomerService {
  width: fit-content;
  font-family: Helvetica, Arial, sans-serif;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
  margin-bottom: 1rem !important;
}

#timeTravel {
  width: auto;
  border-radius: 50px;
  background-color: var(--timeMenu-color);
  margin-left: 0.7rem;
  width: fit-content;
  height: 45px;
  font-family: Helvetica, Arial, sans-serif;
  /*

font-style: normal;
font-weight: bold;
line-height: 24px;
letter-spacing: 0.5px;
color: #FFFFFF;
*/
}
#timeTravelMap {
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: var(--timeMenu-color);
  margin-left: 0.7rem;
  width: fit-content;
  height: 45px;
}

.timeLabelMap {
  margin: 12px;
}
#total {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 17px;
}
#traceContainer {
  overflow: hidden;
  display: flex;
}
#traceExp {
  margin-left: 30px;
  margin-top: 10px;
}
#traceMenu {
  background-color: #10101c;
  border-right: 1px solid slateblue;
}
.circleOrange {
  border: 1px solid darkorange;
  border-radius: 50%;
  background-color: darkorange;
  height: 30px;
  width: 30px;
}
.circleExp {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 7px;
  margin-top: 2px;
}
#goodText {
  color: green;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
#badText {
  color: red;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
#mediumText {
  color: darkorange;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}
.circleWhite {
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
  height: 30px;
  width: 30px;
}
.circleBlue {
  border: 1px solid blue;
  border-radius: 50%;
  background-color: blue;
  height: 30px;
  width: 30px;
}
.circleYellow {
  border: 1px solid yellow;
  border-radius: 50%;
  background-color: yellow;
  height: 30px;
  width: 30px;
}
.circleRed {
  border: 1px solid red;
  border-radius: 50%;
  background-color: red;
  height: 30px;
  width: 30px;
}
.circleGreen {
  border: 1px solid green;
  border-radius: 50%;
  background-color: green;
  height: 30px;
  width: 30px;
}
#traceButtonDiv {
  margin-left: 1%;
}
#changeTrace {
  font-size: 18px;
  margin-top: 20px;
  color: white;
  background-color: #182142;
  border: 1px solid slateblue;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
}
#ispExp {
  margin-top: 20px;
  margin-left: 5px;
}
#noDataTrace {
  position: absolute;
  z-index: 9999;
  margin-left: 50%;
  background-color: rgb(14, 14, 24);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
}
#traceDate {
  margin-top: 9px;
  margin-left: 30px;
}
.react-datepicker__input-container input {
  width: 170px;
  background-color: #182142;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #182142;
  border-radius: 5px;
}
.mapInfo {
  color: black;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  display: table-row-group;
  vertical-align: inherit;
}
.mapInfoTitle {
  font-size: 12px;
  font-weight: bold;
  display: flex;
}
.mapInfoInfo {
  font-size: inherit;
  font-size: 12px;
  display: table-cell;
}
.mapInfoInfoRed {
  font-size: inherit;
  font-size: 12px;
  display: table-cell;
  color: red;
}
.mapInfoInfoBlue {
  font-size: inherit;
  font-size: 12px;
  display: table-cell;
  color: blue;
}
.mapInfoInfoGreen {
  font-size: inherit;
  font-size: 12px;
  display: table-cell;
  color: green;
}
div.google-visualization-tooltip {
  pointer-events: none;
}
/* ------Details------ */
.titleOfList {
  color: whitesmoke;
  font-size: large;
  font-family: Helvetica, Arial, sans-serif;
  /* display: flex; */
}
.infoTitle{
  color: whitesmoke;
  font-size: 25px;
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 15px;
  border-bottom:2px solid rgba(0, 0, 0, 0.2);
}
#detailsMainContainer, #detailsCategoriesMainContainer {
  background-color: var(--details-color);
  margin: 30px;
}
#detailsBoxLeft {
  background-color: #182142;
  height: 110px;
  margin: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: black;
  border-radius: 7px;
}
#detailsBoxRight {
  background-color: #182142;
  height: 110px;
  margin: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: black;
  border-radius: 7px;
}

.detailsBoxMedium {
  background-color: #182142;
  height: 110px;
  margin: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
  color: black;
  border-radius: 7px;
}
#detailsTable {
  background-color: #182142;
  height: 450px;
  color: black;
  border-radius: 7px;
}
#heatMapTable{
  background-color: #182142;
  height: 450px;
  margin: 15px;
  color: black;
  border-radius: 7px;
  width: 50%;
}
#detailsBrandModel {
  background-color: #182142;
  color: black;
  width: 100%;
  height: 100px;
  margin-bottom: 15px;
  border-radius: 7px;
}
#detailsRec {
  background-color: #182142;
  color: black;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 7px;
  height: 170px;
}
#detailsCoverage, #detailsBrandModelAndroid {
  background-color: #182142;
  color: black;
  width: 100%;
  height: 160px;
  margin-bottom: 15px;
  margin-right: 15px;
  border-radius: 7px;
}
#detailsPlacement {
  background-color: #182142;
  color: black;
  width: 100%;
  height: 160px;
  margin-bottom: 15px;
  border-radius: 7px;
}
#detailsTrace {
  background-color: #182142;
  color: black;
  border-radius: 7px;
  margin-bottom: 35px;
  margin-top: 30px;
  height: 550px;
}

#detailsTraceTable {
  background-color: #182142;
  color: black;
  border-radius: 7px;
  margin-bottom: 35px;
  margin-top: 30px;
  padding-bottom: 15px;
}
#detailsMap {
  background-color: #182142;
  color: black;
  height: 450px;
  margin: 15px;
  border-radius: 7px;
  width: 50%;
}
#detailsHeatMap {
  background-color: #182142;
  color: black;
  height: 450px;
  margin: 15px;
  border-radius: 7px;
}
.BoxTitle {
  color: #fff !important;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
  margin-top: 2px;
}
.BoxTitleValue {
  color: rgb(250, 183, 58) !important;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.details-chart-title {
  color: #fff !important;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  padding: 7px 15px 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.RecommendationStyle {
  color: rgb(250, 183, 58) !important;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 15px;
}
#stylebutton {
  background-color: "var(--details-color)";
  height: "50px";
  width: "100%";
  color: "white";
  font-weight: "bold";
  margin-top: "10px";
}
.MuiTableCell-stickyHeader {
  z-index: 0 !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: rgb(230, 82, 109) !important;
}
.MuiTab-textColorPrimary {
  color: white;
}
.RCP {
  width: auto !important;
}
.RCP svg {
  width: 100px !important;
  height: 100px !important;
}
.node-label-text-path {
  fill: white !important;
  text-shadow: none !important;
  stroke: none !important;
  /* margin-left:"-20px" */
}
#timeTravelTraceTable {
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  background-color: var(--timeMenu-color);
  margin-left: 0.7rem;
  width: fit-content;
  height: 45px;
  display: inline-flex;
}

/* Grid Vİew */

.GridViewTableContainer {
  margin-top: 30px;
}

.jss1 {
  width: 30% !important;
}
#sunburstNoData {
  width: 100%;
  height: 900px;
  background-color: rgb(24, 33, 66);
  font-size: 25px;
  font-family: Helvetica, Arial, sans-serif;
  padding: 130px;
}
.react-datepicker-popper {
  z-index: 2;
}
.tableTitle {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  color: #8c8ba6 !important;
  font-size: 11px !important;
}
.tableTitleMember {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  color: #8c8ba6 !important;
}
.membersRow {
  color: wheat !important;
}
#addMember {
  font-size: 15px;
  background-color: #182142;
  color: white;
  border: none;
}
.addMemberLabel {
  display: block;
  color: white;
}
.memberInfoName,
.memberInfoID,
.memberInfoMacAddress {
  outline: none;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border: none;
  text-align: center;
}
#addMemberDiv {
  margin-top: 20px;
  background-color: rgba(29, 27, 65, 0.6);
}
.memberFromGroup {
  margin-left: 50px !important;
}
.addMemberSubmit {
  position: absolute;
  right: 25px;
  margin-top: 10px;
  height: 50px;
  border: 1px solid black;
  width: 50px;
  background-color: #182142;
  color: white;
}
#membersPaper {
  margin-top: 20px;
  background-color: rgba(29, 27, 65, 0.6);
  font-size: 25px;
  font-family: sans-serif;
  color: white;
  overflow-x: auto;
}
#gridSettingsButton {
  margin-right: 25px;
  background-color: #fab73a;
  font-family: Arial, Helvetica, sans-serif;
  height: 35px;
  border: 1px solid black;
  width: 80px;
  color: white;
  font-weight: bold;
}
#acpSettingsPaper,
#roomSettingsPaper {
  margin-top: 20px !important;
  background-color: rgb(24, 33, 66);
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  width: 47%;
  margin: 15px;
}
.gridTitle {
  color: #8c8ba6 !important;
  font-weight: bold !important;
}
.gridRow {
  color: #8c8ba6 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.addGrid {
  margin-top: 10px;
  background-color: white !important;
  border: none;
  font-size: 20px;
  text-align: center;
}
.gridAddButton {
  margin: 10px;
  width: 100px;
  color: white;
  height: 35px;
  background-color: #fab73a;
  border: none;
  font-size: 15px;
  font-weight: bold;
}

#noLocation {
  margin-top: 20px;
  color: dimgray;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
}
#timeZoneTitle {
  justify-content: center;
  margin-top: 25px;
  font-family: sans-serif;
  font-size: 25px;
}
#timeZoneSelect {
  background: none;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  width: 125px;
  font-weight: bold;
  font-family: Helvetica, Arial, sans-serif;
}
#setTimeZone {
  width: 75px;
  margin-left: 50px;
  color: white;
  background: #fd6d5b;
  border: none;
  border-radius: 4rem;
}
#whatsnews {
  position: absolute;
  padding: -16pc;
  margin-top: 25px;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  color: #ffffff;
}
#goToSettings {
  font-family: Roboto;
  text-decoration: underline;
  color: #ffffff;
  opacity: 0.8;
  font-size: 14px;
  cursor: pointer;
}
#goToSettings:hover {
  color: blue !important;
}
#addAPInfo {
  font-size: 15px;
  margin: 10px;
  opacity: 0.4;
}
#companyAPList {
  width: 250px;
  font-size: 13px;
  height: 35px;
}
#companyRoutersSelect {
  font-size: 13px;
  width: 250px;
  height: 37px;
}
#mapFilterSelect {
  margin-left: 50px;
  margin-top: 15px;
  color: white;
  width: 240px;
  height: 35px;
  background-color: #243265;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  outline: none;
}

.datePicker {
  background-color: #243265 !important;
  height: 35px !important;
  width: 260px !important;
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 10px !important;
  color: white;
  font-size: 16px;
}

.popup-content {
  width: auto !important;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
}
.popup-overlay {
  background-color: #00000050;
}
