.login-page {
  background-color: #000a32;
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding-bottom: 100px;
}

#signUpTitle {
  margin-top: 80px;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 76px;
  /* identical to box height, or 127% */

  text-align: center;
  letter-spacing: 1px;

  /* surface */

  color: #ffffff;

  /* 24 dp */

  text-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
    0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
}

#signUpGroup {
  min-width: 322px;
  padding: 10px;
  border: solid rgba(255, 255, 255, 0.6) 1px;
  background-color: transparent;
  border-radius: 4px;
  width: 20%;
  height: 56px;
  margin-top: 30px;
  margin-left: 40%;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 960px) {
  #signUpGroup {
    width: 95% !important;
    margin-left: 2.5% !important;
  }
}

#signUpGroup:hover {
  border: solid #6200ee 1px;
}

#signUpGroup:hover::placeholder {
  color: #6200ee;
}

#signUpGiataID {
  min-width: 322px;
  margin-top: 30px;
  padding: 10px;
  border: solid rgba(255, 255, 255, 0.6) 1px;
  background-color: transparent;
  border-radius: 4px;
  width: 20%;
  margin-left: 40%;
  height: 56px;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 960px) {
  #signUpGiataID {
    width: 95% !important;
    margin-left: 2.5% !important;
    margin-bottom: -10px !important;
  }
}

#signUpGiataID:hover {
  border: solid #6200ee 1px;
}

#signUpGiataID:hover::placeholder {
  color: #6200ee;
}

#signUpGiataIDDesc {
  margin-top: 5px;
  margin-bottom: -15px;
  height: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  letter-spacing: 0.4px;
  margin-left: 40%;

  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 960px) {
  #signUpGiataIDDesc {
    width: 95% !important;
    margin-left: 5% !important;
    margin-top: -16px;
  }
}

#timeZoneSelectOption {
  min-width: 322px;
  margin-top: 30px;
  margin-left: 40%;
  padding: 10px;
  border: solid rgba(255, 255, 255, 0.6) 1px;
  background-color: transparent;
  border-radius: 4px;
  width: 20%;
  height: 56px;

  color: rgba(255, 255, 255, 0.6);
}

#companyTypeSelectOption {
  min-width: 322px;
  margin-top: 30px;
  margin-left: 40%;
  padding: 10px;
  border: solid rgba(255, 255, 255, 0.6) 1px;
  background-color: transparent;
  border-radius: 4px;
  width: 20%;
  height: 56px;
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 960px) {
  #timeZoneSelectOption,
  #companyTypeSelectOption {
    width: 95% !important;
    margin-left: 2.5% !important;
  }
}

#timeZoneSelectOption:hover,
#companyTypeSelectOption:hover {
  border: solid #6200ee 1px;
  color: #6200ee;
}

#submitButton{
  background: linear-gradient(280.15deg, #1543F0 0%, #0090FF 100%);
  border: 1px solid #0984E3;
  /* 12 dp */

  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
}
