.user-list {
    list-style: none !important;
    padding: 0;
  }
  .user-item {
    padding: 10px;
    margin: 5px;
    cursor: pointer;
  }
  .red {
    background-color: #ff7675;
  }
  
  .yellow {
    background-color: #f1c40f;
  }
  
  .green {
    background-color: #2ecc71;
  }
  
  .BoxTitleValueCom {
    color: rgb(250, 183, 58) !important;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
  }
  .BoxTitleCom {
    color: #fff !important;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
  }
  .detailsBoxMediumCom {
    background-color: #182142;
    height: 80px;
    width: 200px;
    margin: 5px;
    border-radius: 7px;
    word-wrap: break-word;
  }
  .indicatorCom {
    font-size: 30px;
    margin-top: -125px;
  }

  .progress-barsCom {
    color: #fff;
    display: flex;
    gap: 10px;
    margin-top: 50px;
  }
  
  .progress-barCom {
    background-color: #182142;
    height:250px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .user-chart {
    margin: 20px;
    padding: 20px;
  }
  .workerName{
    height:"45px";
    color: white;
    border-bottom: 1px solid white;
  }
  .progress-barCom div svg {
    height: 240px !important;
    width: auto !important;
  }
  .indicatorStringCom{
    margin-top: 40px;
    font-size: 25px;
    font-weight: bold;
  }
  .progressString-barCom{
    background-color: #182142;
    height:125px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .progressStringRecommendation-barCom{
    background-color: #182142;
    height:250px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
  }
  .indicatorStringComRecommendation {
    margin-top: 25px;
    font-size: 18px;
    margin-left: 10px;
    margin-right: 10px;
  }