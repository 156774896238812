#content-body {
  min-height: 100vh;
}
#gridModemPlacementButton {
  margin-left: 25px;
  background-color: #fab73a;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  height: 55px;
  width: 170px;
  border: 1px solid #00000050;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

#gridSettingsBtn {
  margin-right: 25px;
  background-color: #fab73a;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  height: 55px;
  border: 1px solid #00000050;
  width: 150px;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.activeTableRow {
  background-color: #fab73a60 !important;
  text-align: center !important;
  color: #182142 !important;
}

.activeTableCell {
  background-color: #fab73a60 !important;
  text-align: center !important;
  color: #182142 !important;
}

@media (max-width: 480px) {
}
#filterselectGrid {
  height: 100% !important;
  width: 250px !important;
  margin-left: 5px !important;
  font-size: 10px !important;
  padding: 5%;
}

.mpUpArrow {
  color: white;
}

.mpDownArrow {
  color: white;
}

.collapsibleTitle {
  text-align: left;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 10px;
  border-bottom: 1px solid;
  color: #fab73a;
}
.collapsibleTableCell {
  color: #8c8ba6 !important;
  padding: 5px !important;
  font-size: 12px !important;
}
.collapsibleTableHeaderRow {
  border-bottom: 2px solid white;
}
.collapsibleTableHeaderCell {
  text-align: left !important;
  padding: 5px !important;
  color: white !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  vertical-align: top !important;
  border-right: 1px solid #ffffff30;
}
