.react-flow__renderer {
  z-index: 0 !important;
}

.flowGraphContainer {
  width: 100%;
}

@media (min-width: 1200px) {
  .flowGraphContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.flowGraphContainer {
  overflow-x: auto;
}
.react-flow {
  overflow-x: auto;
}

.flowGraphWrapper {
  height: 600px;
  width: 1080px;
}
