.popProblemsTableTitle {
  font-family: Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  color: #8c8ba6 !important;
  font-size: 15px !important;
}
.popProblemsRow {
  color: #8c8ba6 !important;
  font-size: 12px !important;
}

.popProblemsCell {
  color: #8c8ba6 !important;
  font-size: 12px !important;
}

.applyButton {
  background-color: #e6526d;
  color: white;
  border-radius: 50px;
  border: none;
  margin-bottom: 0px !important;
  padding: 6px 12px;
  font-size: 16px;
  margin-top: 0px !important;
}

.dateClass {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap:wrap;
}

@media (max-width: 670px) { 
  .dateClass {
    max-width: 300px;
}
}
.MuiDataGrid-iconButtonContainer {
  width: 0;
}
.MuiDataGrid-iconButtonContainer:hover {
  width: 20%;
}

.MuiTablePagination-root {
  color: #8c8ba6 !important;
}
