.datePicker {
  background-color: #243265 !important;
  height: 35px !important;
  width: 260px !important;
  border: 1px solid rgb(118, 118, 118) !important;
  border-radius: 10px !important;
  color: white;
  font-size: 16px;
}
@media (max-width: 768px) {
  #dateFilter {
    margin-top: 0px !important;
  }
  #filterItem {
    margin: 2% !important;
  }
}

.dateTitle {
  font-size: 14px;
  color: white;
  text-align: left;
  margin: 0;
}
