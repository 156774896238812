.showHeatMapDetailsButton {
  background-color: rgb(250, 183, 58);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: -5px;
  border: none;
  border-radius: 4px;
}

#heatMapDetails {
  background-color: #182142;
  color: black;
  border-radius: 7px;
  margin-bottom: 35px;
  margin-top: 30px;
}
.heatMapDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 15px;
  padding-bottom: 50px;
  border-bottom: 1px solid #ffffff60;
}
.heatMapDetailsChildContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .heatMapDetailsChildContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap !important;
    justify-content: space-around;
    overflow-x: scroll;
  }
}
.heatMapDetailsContainerNoData {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.heatMapDetailsChart {
  height: 150px;
  width: 150px;
}

.heatMapDetailsChart:hover {
  transform: scale(1.2);
}
