
.accountPaper{
    background-color: transparent !important;
    transition: none !important;
    box-shadow: none !important;
}

.accountCompanySettings{
    width: 85%;
    height: 320px;
    margin: 5%;
    background-color: var(--chart-color);
    border-radius: 5px;
}

.accountUserSettings{
    width: 85%;
    height: 320px;
    margin: 5%;
    background-color: var(--chart-color);
    border-radius: 5px;
}

.accountInviteToGroup{
    width: 85%;
    height: 320px;
    margin: 5%;
    background-color: var(--chart-color);
    border-radius: 5px;
}

.accountInformation{
    width: 85%;
    height: 320px;
    margin: 5%;
    background-color: var(--chart-color);
    border-radius: 5px;
}

.accountSettingsHeader{
    width: 100%;
    color: rgb(230, 82, 109);
    font-family: Helvetica, Arial, sans-serif;
    margin-top: 15px;
    font-weight: 700;
    font-size: medium;
    text-align: center;
    
}

.accountCompanySettingsContentArea{
    height: 190px;
    width: 100%;
}
.accountUserSettingsContentArea{
    height: 190px;
    width: 100%;
}
.accountInviteToGroupContentArea{
    height: 190px;
    width: 100%;
}
.accountCloudArea{
    height: 100%;
    width: 92.5%;
    margin: 2.5%;
    background-color: var(--chart-color);
    border-radius: 5px;
    padding-bottom: 5%;
}
.accountCloudContentArea{
    height: 100%;
    width: 100%;
}
.accountInfoContentArea{
    height: 190px;
    width: 100%;
    padding:10%;

    
}
#standard-basic {
    padding: 10px;
    margin-top: -10px;
    color: white;
  }

.invitemail {
    margin-left: 0px !important;
  }




#invitemailtext{
    padding: 10px;
    width: 230px !important;
}



#changePP{
    color: white;
    text-decoration: underline;
    cursor: pointer;
    margin-left: -35px;
}

.accountCompanySettingsCompanyName{
    margin-top: 0px;
}

.accountCompanySettingsGiataID{
    margin-top: 10px;
}



.accountUserSettingsSurname{
    margin-top: 10px;
}

.accountUserSettingsNewPass{
    margin-top: 10px;
}
.accountUserSettingsConfirmPass{
    margin-top: 10px;
}

.settingsSaveButon{
    margin: 20px !important;
}

#accountInviteExplanation{
    font-size: 13px;
    opacity: 0.3;
    font-family: Helvetica, Arial, sans-serif;
    margin-top:0 !important;
    color: white;
}

#accountForm{
    width: 100% !important;
}

.popupLogoWrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.popupSaveButton{
    margin-bottom: 10px;
}

#uploadButton {
    margin-right: 20px;
  }

#imgShowLogo {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    margin-left: 20px;
    border-radius: 50%;
}

#updateduser,
#updatedCompany{
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

#savePSettings {
    float: right;
}

#saveUSettings {
    float: right;
}

#invitEmail {
    text-align: center;
    display: -webkit-inline-box;
    width: 250px;
}

#invitEmailText {
    padding: 10px;
    width: 230px !important;
    color: white;
}

#roleSelect {
    color: rgba(255, 255, 255, 0.383);
    outline: none;
    background: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    width: 250px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.383);
    font-size: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

.rol {
    background: rgba(29, 27, 65, 0.6);
}

#inviteSend {
    color: red;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

#sendInviteButton {
    float: right;
  }
  #invitEmailText-label {
    color: hsla(0,0%,100%,.383);
    font-size: 15px;
    margin-top: 10px;
    text-indent: 5px;}
#accountSaveCloudApiKey{
    margin-top: 10px;
    margin-bottom: 10px;

}

.accountSavedApiKeysTable{
    text-align: left;
    width: 100%;


}
.accountSavedApiKeysTableTitle{
    height: 30px;
    color: rgb(230, 82, 109);
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: small;
    margin-top: 5%;
}
.accountSavedApiKeysThead{
    width: 100%;
    height: 50px;
    color: #ffffff;
    border-bottom: 1.5px solid #A9A9A990;
}
.accountSavedApiKeysRow{
    width: 100%;
    height: 70px;
    color: #ffffff;
    border-bottom: 1.5px solid #A9A9A990;
  
}



.accountSavedApiKeysColumn1{
    width: 20%;
    padding: 8px;
    text-align: left;
    
}

.accountSavedApiKeysColumn2{
    width: 20%;
    padding: 8px;
    text-align: left;
   
}

.accountSavedApiKeysColumn3{
    width: 20%;
    padding: 8px;
    text-align: left;
   
}

.accountSavedApiKeysColumn4{
    width: 20%;
    padding: 8px;
    text-align: left;
   
}
.accountSavedApiKeysColumn5{
    width: 20%;
    padding: 8px;
    text-align: right;
   
}

.accountSavedApiKeysColumn6{
    width: 10%;
    padding: 8px;
    text-align: right;
   
}

.accountTableWrapper{
    height: 650px;
    margin-top: 1%;
    overflow: auto;
    margin-left: 10%;
    margin-right: 10%;
    border: 1px solid #ffffff30;
    border-radius: 5px;
}

.accountExpandButton{
    height: 30px;
    width: 30px;
    color: white !important;
    border: 1px solid white !important;
    border-radius: 20px !important;
    font-size: 18px !important;
    min-width: 30px !important;
}


.accountEditButton{
    min-width: 0px !important;
    width: 30px;
    color: white !important;
}
.accountDeleteButton{
    margin-left: 10% !important;
    min-width: 0px !important;
    width: 30px;
    color: white !important;
}
.accountRefreshButton{
    margin-left: 10% !important;
    min-width: 0px !important;
    width: 30px;
    color: white !important;
}

.accountSavedApiKeysInput{
    margin-top: -15px;
}

.accountNoSavedApiKey{
    text-align: center;
    color: #ffffff30;
    margin-top: 20%;
    font-size: 18px;
}

.accountCloudApi{
    display: flex;
    justify-content: left;
}

.cloudApiInput{
    width: 230px;
}

#cloudRefreshButton{
    margin-left: 25px !important;
}
#refreshCloudText{
    font-size: 16px;
    margin-left: 115px;
    margin-top: 15px;
    color: rgba(255, 255, 255, 0.6);
}

#timeZoneSelectOptionCloud{
    margin-top: 3px;
    margin-left: 25px !important;
    padding: 3px;
    border: solid rgba(255, 255, 255, 0.6) 1px;
    border-radius: 4px;
    width: 115px;
    height: 45px;
    margin-left: 0;
    color: rgba(255, 255, 255, 0.6);
    
}

.cloudTimeOption{
   color: rgba(29, 27, 65, 0.6);
}

.infoIcon{
    float: right;
    color: white;
    margin-top: 8px;
    margin-right: 8px;
}

.infoBox{
    display: flex;
    margin-top: 10px;
    margin-left: 15px;
}

.infoBox:hover .infoTextBox{
    display: block;
    position: absolute;
}

.infoText{
    color: #ffffff90;
    font-size: 12px;
}
.infoTextBox{
    display: none;
    float: right;
    position: relative;
    margin-bottom: -100px;
    margin-left: 25px;
    background-color: rgba(0 , 0, 0, 0.4);
    padding: 30px;
    border-radius: 5px;
    width: 350px;
    
}

.cloudSpecialApi{
    margin-top: -10px !important;
    color: white !important;
    padding: 10px !important;
}

.accountCollapsibleTableHeaderRow{
    
    border-bottom: 2px solid #ffffff30;
}

.accountCollapsibleTableRow{
    
    border-bottom: 1px solid #ffffff30;
}

.accountCollapsibleTableCell{
    border: 0 !important;
    color: #ffffff90 !important;
}