.chromeExtensionBox {
  background-color: #182142;
  overflow-x: auto;
}
.cExtHeader {
  color: #fab73a !important;
}
.cExtCell {
  color: white !important;
}
.cExtCellResponses {
  color: black !important;
}

.responsePopupTitle {
  font-size: 16px;
  color: #fab73a !important;
}

.popup-content {
  overflow-x: auto;
}

.resultTableTitle {
  font-size: 16px;
  font-weight: 700;
  margin: 20px;
  text-align: left;
  color: #fab73a !important;
  border-bottom: 0.5px solid #fab73a;
}

.heatMapDetailsTitle {
  color: white;
  font-size: 18px;
  text-align: center;
}

.customerSearch {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 30%;
  background-color: #182142 !important;
  color: #e2e2e2 !important;
}

@media (max-width: 768px) {
  .customerSearch {
    width: 100% !important;
  }
}
