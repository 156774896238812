.filterArea {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .filterArea {
    display: flex;
    flex-direction: column;
  }
}
.download {
  float: right;
  margin: 20px;
  margin-left: -35px !important;
}

.dropdownFilter {
  font-size: 16px;
  color: white;
  width: 110px;
  height: 35px;
  background-color: #243265;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: none;
  outline: none;
  text-align: center;
}
.filterItem {
  margin: 2%;
}
.filterItemFlex {
  margin: 2%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .filterItemFlex {
    margin: 2%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .applyButton {
    margin-bottom: 0px !important;
    margin-right: 10px;
  }
  .dateTitle {
    text-align: center !important;
  }
}
.applyButton {
  background-color: #e6526d;
  color: white;
  border-radius: 50px;
  border: none;
  margin-bottom: 10px;
  padding: 6px 12px;
  font-size: 16px;
  margin-top: 20px;
}

.filterTitle {
  font-size: 14px;
  color: white;
  text-align: left;
  margin: 0;
}

#cServicePaper {
  background-color: rgba(29, 27, 65, 0.6);
  font-size: 25px;

  font-family: sans-serif;
  color: #8c8ba6;
  margin-bottom: 12px;
  overflow-x: auto;
}

.cServiceCustomersRow {
  color: #8c8ba6 !important;
  font-size: 10px !important;
  cursor: pointer;
}
.cServiceCustomersRow:hover {
  color: blue !important;
}

.cServiceCustomersRow:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  margin-left: 5px;
  margin-top: -5px;
}

.cServiceRow {
  color: #8c8ba6 !important;
  font-size: 10px !important;
}

.cServicecomplaintRow {
  color: red !important;
  font-size: 10px !important;
}
