#loginLogo {
  height: 34px;
  width: 34px;
  margin-right: 13px;
  margin-left: 10%;
}

#ambeent-logo {
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}
