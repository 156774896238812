#timeTravel {
  background-color: transparent !important;
  position: relative !important;
  display: table-footer-group !important;
}

.setTimeButton {
  width: 70px;
  border: none;
  background-color: #182142;
  border-radius: 50px;
  height: 35px;
  color: #fff;
  margin: 6px;
}
.setTimeButton {
  margin: 25px !important;
}

.setTimeButtonBlocked {
  width: 70px;
  border: none;
  background-color: rgba(24, 33, 66, 0.3);
  border-radius: 50px;
  height: 35px;
  color: #f8f9fa66;
  margin: 25px !important;
}

.timeLabel {
  margin: 12px;
}

.savePSettings2 {
  background-color: rgb(230, 82, 109);
  border: none;
  color: white;
  width: 150px;
  height: 35px;
  margin: 6px;
  border-radius: 50px;
  /* transition:transform 2s; */
}

/* #chartMenu {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
} */

#chartMenu {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .db-chart > .col-lg-6 {
    padding-right: 0;
  }
}

@media (max-width: 992px) {
  .db-chart > .col-lg-6 {
    width: 100% !important;
  }
}

.chart {
  display: inline;
}
@media (max-width: 768px) {
  .chart {
    margin-left: -80px;
    margin-top: 10px;
  }

  #chartMenu {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
@media (min-width: 1600px) {
  .chart {
    margin-left: 10px;
  }
}

.chart svg {
  background-color: transparent !important;
}

.chart-card {
  background-color: var(--chart-color);
  border-radius: 4px;
  overflow: hidden;
}

.chartTitle {
  font-size: 17px;
  padding: 7px 15px 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
}

.chart-title {
  font-size: 17px;
  padding: 7px 15px 7px 15px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  margin-left: 5%;
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.5px;
  /* surface */
  color: #ffffff;
}

.chartInfoButton {
  float: right;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  color: #fff;
  background: var(--infoButton-color);
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.changeChartButton {
  float: right;
  background-size: contain !important;
  border: none;
  height: 25px;
  width: 25px;
  border-radius: 5px;
  margin-right: 5px;
}

.overviewActiveUser {
  color: white;
  padding: 30px 40px;
  font-size: 20px;
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
  .overviewActiveUser {
    color: white;
    padding: 10px 40px;
    font-size: 20px;
  }
}

.overviewMeasurements {
  color: white;
  font-size: 20px;
}
