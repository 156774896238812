.ctableTitle{
    font-family: Helvetica, Arial, sans-serif !important;
    font-weight: bold !important;
    color: #8c8ba6 !important;
    font-size: 15px !important;
  }

  .crowIDCell {
    color: #8c8ba6 !important;
    font-size: 12px !important;
    cursor: pointer;
  }
  .crowIDCell:hover {
    color: blue !important;
  }
  
  .crowIDCell:hover .tooltiptext {
    visibility: visible;
  }

  .ccsRow {
    color: #8c8ba6 !important;
    font-size: 12px !important;
  }