.tabWrapper {
  background-color: var(--timeMenu-color);
  margin-bottom: 100px;
  padding: 15px;
}

.tabButtonWrapper {
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: left;
  border-bottom: 1px solid rgba(29, 27, 65, 0.6);
  overflow: auto;
}
@media (max-width: 768px) {
  .tabButtonWrapper {
    margin-bottom: 55px;
  }
}

.activeTabButton {
  border: none;
  background-color: #182142;
  color: white;
  padding: 20px;
  font-size: 14px;
}

.tabButton {
  border: none;
  background-color: transparent;
  color: white;
  padding: 20px;
  font-size: 14px;
}

@media (max-width: 600px) {
  .activeTabButton {
    border: none;
    background-color: #182142;
    color: white;
    padding: 10px;
    font-size: 13px;
  }
  
  .tabButton {
    border: none;
    background-color: transparent;
    color: white;
    padding: 10px;
    font-size: 13px;
  }
}