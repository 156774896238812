.parentNode {
  font-size: 16px;
  text-align: center;
  border: 1px solid #ffffff60;
  border-radius: 3px;
  background-color: #182142;
  color: white;
  width: 150px;
  padding: 10px;
}

.parentNodeBad {
  font-size: 16px;
  text-align: center;
  border: 1px solid #ffffff60;
  border-radius: 3px;
  background-color: #ff000060;
  color: white;
  width: 150px;
  padding: 10px;
}
.parentNodeMedium {
  font-size: 16px;
  text-align: center;
  border: 1px solid #ffffff60;
  border-radius: 3px;
  background-color: #ffa50060;
  color: white;
  width: 150px;
  padding: 10px;
}

.parentNodeGood {
  font-size: 16px;
  text-align: center;
  border: 1px solid #ffffff60;
  border-radius: 3px;
  background-color: #7fff0060;
  color: white;
  width: 150px;
  padding: 10px;
}

.childNode {
  background-color: #182142;
  padding: 5px;
  border-radius: 10px;
}
.childLabel {
  color: white;
  font-size: 14px;
  margin-bottom: 0px;
  border-bottom: 1px solid #00000060;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #00000060;
  margin: 5px;
}
.triangle span {
  position: relative;
  top: 8px;
  left: -4px;
}
.square {
  height: 30px;
  width: 30px;
  background-color: #00000060;
  margin: 5px;
}
.square span {
  position: relative;
  top: 6px;
  left: -1px;
}
.circle {
  height: 30px;
  width: 30px;
  background-color: #00000060;
  border-radius: 50%;
  margin: 5px;
}
.circle span {
  position: relative;
  top: 5px;
  left: 0px;
}

.childValue {
  font-size: 16px;
  color: #fafafa;
  margin-top: 5px;
  margin-bottom: 5px;
}

.deviceLabel {
  color: white;
  font-size: 16px;
}

.deviceAmbId {
  color: white;
  font-size: 14px;
  max-width: 180px;
}

.routerLabel {
  color: white;
  font-size: 16px;
  margin-bottom: 0;
}

.routerMac {
  color: white;
  font-size: 14px;
  max-width: 180px;
}
.activeRouter {
  color: green;
}
.dropdownFilter {
  max-width: 110px;
}

.recommendationBox {
  width: 500px;
  height: 700px;
  background-color: #182142;
  padding: 55px;
  border-radius: 10px;
  text-align: left;
}
.recomText {
  color: white;
  font-size: 20px;
  text-align: justify;
}
.recomTitle {
  color: white;
  font-size: 42px;
}

.closedBullet {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ffffff;
  font-size: 18px;
}

.openBullet {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #ffffff;
  font-size: 18px;
  overflow: hidden;
}

.bulletList {
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 5px;
  padding: 10px;
  overflow: auto;
  height: 55%;
}
