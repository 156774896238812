.multiSelect {
  border: 1px solid #ffffff60 !important;
  font-size: 16px;
  color: white;
  height: 30px;
  width: 200px;
  background-color: #243265;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  margin-top: 5px !important;
}

.MuiInput-input {
  color: white !important;
  padding-left: 10px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #243265 !important;
}
