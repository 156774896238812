.tableTitle{
    font-family: Helvetica, Arial, sans-serif !important;
    font-weight: bold !important;
    color: #8c8ba6 !important;
    font-size: 11px !important;
  }

  .rowIDCell {
    color: #8c8ba6 !important;
    font-size: 10px !important;
    cursor: pointer;
  }
  .rowIDCell:hover {
    color: blue !important;
  }
  
  .rowIDCell:hover .tooltiptext {
    visibility: visible;
  }

  .csRow {
    color: #8c8ba6 !important;
    font-size: 10px !important;
  }