.clientIntegrationTitle {
  margin-top: 5%;
  color: white;
  font-size: 18px;
}
.storeComps {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .storeComps {
    display: flex;
    flex-direction: column;
  }
}

.storeField {
  width: 100%;
  max-width: 230px;
  border: 1px solid gray;
  height: 100%;
  border-radius: 6px;
  background-color: rgb(0, 0, 0);
  margin: 10px;
}

.storeLogo {
  width: 48px;
  height: 48px;
  margin: 12px;
}

.downloadText {
  color: rgb(250, 249, 246);
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: left;
}

.storeText {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
}

.divider {
  border-bottom: 1px solid #ffffff60;
  margin-top: 5%;
}
.enterCodeComps {
  margin-top: 5%;
  align-items: center;
  max-height: 42px;
  margin-left: 30%;
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .enterCodeComps {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
}
.inputBox {
  display: block;
  width: 30%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: #000a32;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.submitButton {
  background: linear-gradient(134.49deg, #0090ff 0%, #1543f0 105.05%);
  margin-top: 24px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 50px;
}

.codeTitle {
  margin-top: 14px;
  color: white;
  font-size: 18px;
}

.integrationManuelText {
  color: white;
  font-size: 16px;
  text-align: left;
  width: 50%;
}

.integrationManuelListItem {
  color: white;
  font-size: 16px;
  text-align: left;
}
